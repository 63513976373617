<template>
  <b-container>
    <b-card>
      <b-row class="my-3">
        <b-col>
          <h2>Contact Bookkeeper</h2>
        </b-col>
      </b-row>
      <b-row v-if="!showConfirmation">
        <b-col cols="12">
          <form
            @submit.prevent="contact()"
            action=""
            ref="contactForm"
          >
            <b-row v-if="companies && companies.length > 1">
              <b-col cols="12">
                <b-form-group
                  label="Company"
                  label-align="left"
                >
                  <b-form-select
                    v-model="form.company_id"
                    :options="companies"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >-- Please select a company --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Subject"
                  label-align="left"
                >
                  <b-form-input
                    v-model="form.subject"
                    placeholder="Subject of Message"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Message"
                  label-align="left"
                >
                  <b-form-textarea
                    v-model="form.content"
                    placeholder="Your Message"
                    rows="7"
                    required
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-overlay
                  :show="loading"
                  class="d-inline-block"
                  spinner-small
                >
                  <b-button
                    type="submit"
                    variant="success"
                  >Send Message</b-button>
                </b-overlay>
              </b-col>
            </b-row>
          </form>
        </b-col>
      </b-row>

      <b-row v-if="showConfirmation">
        <b-col>
          <p>Your submission has been received. Thank you.</p>
          <b-button @click.prevent="showConfirmation = false">Send Another Message</b-button>
        </b-col>
      </b-row>

      <div v-if="selectedCompany && selectedCompany.calendar">
        <hr style="border-top: 1px solid white" />
        <div class="mb-3">or</div>
        <b-overlay :show="loading">
          <b-row no-gutters>
            <b-col>
              <b-button @click="showTidycal(selectedCompany.calendar, '30-minute-call')">
                <b-icon-phone></b-icon-phone> Schedule A Call With Your Bookkeeper
              </b-button>
            </b-col>
            <b-col>
              <b-button @click="showTidycal(selectedCompany.calendar, '30-minute-screenshare')">
                <b-icon-display></b-icon-display> Schedule A Screenshare With Your Bookkeeper
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </div>
    </b-card>

    <TidyCal
      :show="showTidycalPopup"
      :path="bookingPath"
      :booking-type="bookingType"
      @change="(v) => { this.showTidycalPopup = v }"
    ></TidyCal>
  </b-container>
</template>
<script>
import UserService from '@/services/UserService'
import MessageService from '@/services/MessageService'
import AdminService from '@/services/AdminService'
import TidyCal from '@/components/TidyCal'

export default {
  name: 'Contact',
  components: { TidyCal },
  data () {
    return {
      user: {},
      companies: [],
      selectedCompany: null,
      form: {
        company_id: null,
        subject: null,
        content: null
      },
      loading: false,
      showConfirmation: false,
      showTidycalPopup: false,
      bookingPath: null,
      bookingType: null
    }
  },

  async mounted () {
    this.user = await UserService.getUserInfo()
    let companies = null
    if (UserService.isAdmin()) {
      companies = await AdminService.getCompanies()
    } else {
      companies = await UserService.getCompanies()
    }
    if (companies && companies.length) {
      if (companies.length === 1) {
        this.form.company_id = +companies[0].id
        this.selectedCompany = companies[0]
      }
      companies.forEach((c) => {
        this.companies.push({ value: +c.id, text: c.name, calendar: c.calendar })
      })

      this.companies = this.companies.sort((a, b) => ('' + a.text).localeCompare(b.text))
    }
  },

  methods: {

    showTidycal (path, type) {
      this.bookingPath = path
      this.bookingType = type
      this.showTidycalPopup = !this.showTidycalPopup
    },

    /*
    async showCalendly () {
      let month = new Date().getMonth()
      month++
      if ((month + '').length === 1) {
        month = '0' + month
      }
      window.Calendly.showPopupWidget(
        'https://calendly.com/tana-sheets/30-min-initial-call?embed_domain=streamlinedbusinesssolutions.com&embed_type=Inline&hide_event_type_details=1&month=2021-' + month +
        '&first_name=' + (this.user.firstname || '') + '&last_name=' + (this.user.lastname || '') + '&email=' + (this.user.email || '')
      )
    },
    */

    contact () {
      this.loading = true
      const formData = new FormData()
      formData.append('company_id', this.form.company_id)
      formData.append('subject', this.form.subject)
      formData.append('content', this.form.content)
      MessageService.send(formData).then(
        (response) => {
          this.$aimNotify.notify(response)
          this.showConfirmation = true
          this.form = {
            subject: null,
            content: null
          }
        }
      ).catch(
        (err) => {
          this.$aimNotify.error(err.response)
        }
      ).finally(() => { this.loading = false })
    }
  },

  watch: {
    'form.company_id': function (n, o) {
      if (n && n !== o) {
        this.companies.some((c) => {
          if (+n === +c.value) {
            this.selectedCompany = c
            return true
          } else return false
        })
      }
    }
  }
}
</script>
