<template>
  <b-modal
    id="tidycalPopup"
    v-model="showPopup"
    size="xl"
    scrollable
    hide-footer
    header-class="py-0"
    no-close-on-backdrop
    no-close-on-esc
    @change="(v) => { $emit('change', v) }"
  >
    <div
      id="tidycal-embed"
      :data-path="path + '/' + bookingType"
      data-show-avatar
    ></div>
  </b-modal>
</template>
<script>
export default {
  name: 'TidyCal',

  props: {
    show: Boolean,
    path: String,
    bookingType: {
      type: String,
      default () { return '30-minute-call' }
    }
  },

  data () {
    return {
      showPopup: false
    }
  },

  mounted () {
  },

  watch: {
    show: function (n) {
      this.showPopup = n
      if (this.showPopup) {
        this.$nextTick(() => {
          window.TidyCal.init(document.getElementById('tidycal-embed'))
        })
      }
    }
  }
}
</script>
